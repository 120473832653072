<!--门禁模块--设备页-->
<template>
  <div id="runningState">
    <div class="header">
      <a-form-model :model="form" layout="inline">
        <a-form-model-item :label="$t('elevatorRunning.a2')">
          <a-tree-select v-model="form.value"
              style="min-width: 180px;"
              :tree-data="elevatorSelect"
              :maxTagCount = '1'
              tree-checkable
              search-placeholder="Please select"
              @change="onChange"
            />
      </a-form-model-item>
      </a-form-model>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('elevatorRunning.a1')">
      <my-statecard
         v-for="(item,index) in statusList"
        :key="item.id"
        :elevator = "options[index]"
        :status="item"></my-statecard>
      <div class="empty" v-for="n in 10" :key="'m' + n"></div>
    </div>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item, index) in runicon" :key="index">
          <img :src="item.url" style="height:18px;"/>
          <span>{{ $t(item.name) }}</span>
        </li>
      </ul>
      <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" style="height:18px;"/>
          <span>{{ $t(item.name) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getTenantList, getCardList } from "../../api/door";
import statecard from "./components/stateCard";
import { isEmptrValue,getbuildsiteId,getApiElevatorList,getApiElevatorStateList } from "../../api/elevator";
import moment from "moment";
export default {
  name: "runningState",
  data() {
    return {
      form:{
        region: 0,
        region1: 0,
        value: [],
      },
      loading: false,
      options: [],
      statusList: [
      ],
      elevatorSelect: [],
      icon: [
       {
          name: "elevatorequipment.a6",
          url: require("../../../public/images/iconEleNo/status/equipment01.png"),
        },
        {
          name: "elevatorequipment.a7",
        url: require("../../../public/images/iconEleNo/status/equipment02.png"),
        },
        {
          name: "elevatorequipment.a8",
          url: require("../../../public/images/iconEleNo/status/equipment03.png"),
        },
        {
          name: "elevatorequipment.a9",
          url: require("../../../public/images/iconEleNo/status/equipment04.png"),
        },
        {
          name: "elevatorequipment.a10",
          url: require("../../../public/images/iconEleNo/status/equipment05.png"),
        },
        {
          name: "elevatorequipment.a11",
          url: require("../../../public/images/iconEleNo/status/equipment06.png"),
        },
        {
          name: "elevatorRunning.a3",
          url: require("../../../public/images/iconEleNo/status/staus01.png"),
        },
        {
          name: "elevatorRunning.a4",
          url: require("../../../public/images/iconEleNo/status/staus02.png"),
        },
      ],
      runicon: [
        {
          name: "elevatorRunning.a15",
          url: require("../../../public/images/iconEleNo/11.png"),
        },
        {
          name: "elevatorRunning.a16",
          url: require("../../../public/images/iconEleNo/13.png"),
        },
        {
          name: "elevatorRunning.a17",
          url: require("../../../public/images/iconEleNo/12.png"),
        },
        {
          name: "elevatorRunning.a18",
          url: require("../../../public/images/iconEleNo/14.png"),
        },
        {
          name: "elevatorRunning.a19",
          url: require("../../../public/images/iconEleNo/10.png"),
        },
      ],
      timer: null,
      id:{
        client:0,
        area:0,
        build:0
      }
    };
  },
  async created() {
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
    }).catch((err) => {
        console.log("访问站点转换出错", err);
    });
    const params = {'clientId':this.id.client,'areaId':this.id.area,'buildId':this.id.build}
    let listtemp = []
    await getApiElevatorList(params).then((res)=>{
        if(res.code===200){
          res.data.forEach(
            item =>{
              const option = { value: item.id+'_'+item.mcId+'_'+item.eleInMcNum,title: item.eleName }
              const model = { id: item.id,mcId:item.mcId,eleInMcNum:item.eleInMcNum,clientId: 1,areaId:1,eleName:item.eleName }
              listtemp.push(option)
              this.form.value.push(option.value)
              this.options.push(model)
            }
          )
          this.elevatorSelect =  listtemp
        }else{
          this.elevatorSelect =  listtemp 
        }
      }
     )
  },
  components: {
    "my-statecard": statecard
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  mounted() {
    var that = this
    // 进入页面时调用获取页面左侧列表方法
    // that.navderList();
    that.timer = setInterval(
      function(){
        setTimeout(that.onGetElevatorState(),0)
      },1000
    )
  },
  methods: {
   moment,
   navderList(){
     const params = {'clientId':this.id.client,'areaId':this.id.area,'buildId':this.id.build}
     let listtemp = []
     getApiElevatorList(params).then((res)=>{
        if(res.code===200){
          if(!isEmptrValue(res.code)){
            res.data.forEach(
            item =>{
 
              }
            )
          }
          this.elevatorSelect =  listtemp
        }else{
          this.elevatorSelect =  listtemp 
        }
      }
     )
   },
   onGetElevatorState(){
     if(isEmptrValue(this.options)){
        this.statusList = []
     }else{
      getApiElevatorStateList(this.options).then((res)=>{
          let list = []
          if(!isEmptrValue(res.code)){
            res.data.forEach(
                item =>{
                    // ACD码
                    let acdcode = 0
                    switch(item.eleStatusRep.eleStatauInfo2.code){
                        case 0:acdcode = 0;break; // 故障
                        case 3:acdcode = 3;break; // 停止
                        case 4:acdcode = 4;break; // 检修
                        case 19:acdcode = 19;break; // 消防
                        case 36:acdcode = 36;break; // VIP/专用
                        case 39:acdcode = 39;break; // 正常
                        default :acdcode = 0;break; // 故障
                    }
                    // 运行方向
                    let runDirection = 0
                    switch(item.eleStatusRep.eleStatauInfo2.runDirection){
                        case 0:runDirection = 0;break; // 静止/无方向
                        case 1:runDirection = 1;break; // 上行
                        case 2:runDirection = 2;break; // 下行
                        case 3:runDirection = 3;break; // 上行(运行)
                        case 4:runDirection = 4;break; // 下行(运行)
                        default :runDirection = 0;break; // 故障
                    }
                    //"floor":item.eleStatusRep.eleStatauInfo2.floor
                    const model = {'id':item.id,"code":item.eleStatusRep.code,'name':'',"runDirection":runDirection,"floor":item.eleStatusRep.eleStatauInfo2.floor?item.eleStatusRep.eleStatauInfo2.floor:'',"doorStatus":item.eleStatusRep.eleStatauInfo2.doorStatus?1:0,"abccode":acdcode,"Itm":item.eleStatusRep.eleStatauInfo2.itm?1:0,"lastdate":moment(item.eleStatusRep.recorddate).format('YYYY-MM-DD HH:mm:ss') }
                    for(let i=0;i<this.elevatorSelect.length;i++){
                      if(item.id=parseInt(this.elevatorSelect[i].value.split('_')[0])){
                        model.name = this.elevatorSelect[i].title
                      }
                    }              
                    list.push(model)
              }
            )
          }
          this.statusList = list
      })
     }
   },
   onChange(value, label) {
      let list = []
      let listOption = []
      for(let i=0;i<value.length;i++){
        //  let data = {id:parseInt(value[i].split('_')[0]) ,name:label[i],"code":0,"runDirection":1,"floor":"2","doorStatus":1,"abccode":39,"Itm":1,"lastdate":'0001-01-01T00:00:00' }
        //  list.push(data)
         const model = { id: parseInt(value[i].split('_')[0]),mcId:parseInt(value[i].split('_')[1]),eleInMcNum:parseInt(value[i].split('_')[2]),clientId: 1,areaId:1,eleName:label[i] }
         listOption.push(model)
      }
      // this.statusList = list
      this.options = listOption
    }
   },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#runningState {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
  margin-bottom:5px;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.footer {
  height: 50px;
  align-items: center;
  padding-left: 20px;
}
</style>