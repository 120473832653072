<!--门禁模块--设备卡片组件-->
<template>
  <div id="runningStateCard"  > <!-- @click="goDetails" :element-loading-text="status.code|codeTypeFilter"-->
    <div style="text-align:right;line-height: 24px;padding:8px;margin-bottom:8px;" >
      <img :src="getStatusImages" class="iconImg" />
      <span style="color:#666;padding-right:10px;">{{elevator.eleName}}</span>
    </div>
     <a-spin :spinning="loading" :tip="loadingtext">
    <div>
      <div class="title">
        <span>{{status.floor}}</span>
        <img :src="getRunDirectionImages" style="float:right;witdh:42px;height:42px;padding-right:10px;" />
      </div>
      <div class="des">
      </div>
      <div class="action" @click.stop>
        <span style="color:green;margin-right:4px;">{{$t(itmType[status.Itm].name)}}</span>
      </div>
  </div>
  </a-spin>
  </div>
</template>

<script>
const codeType = {
  0:{
    text:'elevatorRunning.a7',
    iconNo:'10',
    loading: true,
  },
  1:{
    text:'elevatorRunning.a8',
    iconNo:'11',
    loading: true,
  },
  2:{
    text:'elevatorRunning.a9',
    iconNo:'12',
    loading: true,
  },
  3:{
    text:'elevatorRunning.a10',
    iconNo:'13',
    loading: true,
  },
  4:{
    text:'elevatorRunning.a11',
    iconNo:'14',
    loading: true,
  },
  5:{
    text:'elevatorRunning.a12',
    iconNo:'14',
    loading: true,
  },
  6:{
    text:'elevatorRunning.a13',
    iconNo:'14',
    loading: true,
  },
  19:{
    text:'elevatorRunning.a13',
    iconNo:'14',
    loading: true,
  },
  200:{
    text:'elevatorRunning.a14',
    iconNo:'14',
    loading: false,
  },
}
const runDirectionType = {
  0:{
    name:'静止/无方向',
    iconNo:'10',
  },
  1:{
    name:'上行',
    iconNo:'11',
  },
  2:{
    name:'下行',
    iconNo:'12',
  },
  3:{
    name:'运行上行',
    iconNo:'13',
  },
  4:{
    name:'运行下行',
    iconNo:'14',
  },
}
const acdCodeType = {
  39:{
    name:'正常',
    iconNo:'245',
  },
  0:{
    name:'故障',
    iconNo:'244',
  },
  4:{
    name:'检修',
    iconNo:'239',
  },
  3:{
    name:'停止',
    iconNo:'242',
  },
  19:{
    name:'消防',
    iconNo:'2',
  },
  36:{
    name:'VIP/专用',
    iconNo:'1',
  },
  default:{
    name:'故障',
    iconNo:'240',
  },
}
const itmType = {
  0:{
    name:'elevatorRunning.a5',
    iconNo:'10',
  },
  1:{
    name:'elevatorRunning.a6',
    iconNo:'11',
  }
}
export default {
  name: "runningStatecard",
  props: {
    status: {
      type: Object,
    },
    elevator: {
      type: Object
    }
  },
  data() {
    return {
      codeType,
      runDirectionType,
      acdCodeType,
      itmType,
      changeState: false
    };
  },
  methods: {
  },
  computed: {
    loadingtext: function(){
      let text = this.$t(this.codeType[this.status.code].text)
      return text
    },
    // 按钮根据后台返回状态值显示初始状态
    state: function () {
      if (this.status.Itm == 1) {
        return true;
      } else {
        return false;
      }
    },
    loading: function () {
      return this.codeType[this.status.code].loading
    },
    // 卡片图标根据对应iocnNO加载对应图标
    getStatusImages: function () {
      let iconNo = this.acdCodeType[this.status.abccode].iconNo;
      return require("../../../../public/images/iconEleNo/" + iconNo + ".png");
    },
    // 卡片图标根据对应iocnNO加载对应图标
    getRunDirectionImages: function () {
      let runDirectioniconNo = this.runDirectionType[this.status.runDirection].iconNo;
      return require("../../../../public/images/iconEleNo/" + runDirectioniconNo + ".png");
    },
  },
  filters:{
    runDirectionIconNoFilter(type){
      return runDirectionType[type].iconNo
    },
    runDirectionTypeFilter(type){
      return runDirectionType[type].name
    },
    itmTypeFilter(type){
      return itmType[type].name
    },
    codeTypeLoadingFilter(type){
      return codeType[type].loading
    },
    codeTypeFilter(type){
      return codeType[type].name
    },
  }
};
</script>

<style scoped>
#runningStateCard {
  width: 200px;
  height:150px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
}
.iconImg{
    width:24px;
    height:24px;
    float:left;
  }
#runningStateCard:hover {
  border: 1px solid #7682ce;
}
.title {
  height: 42px;
  line-height: 42px;
  padding-left: 10px;
  font-size: 42px;
  font-weight:600;
}
.des{
  height: 24px;
  line-height: 24px;
  padding-left: 10px;
  font-size: 12px;
  color:#666;
  font-weight:400;
  padding-bottom:8px;
}
.action { 
  border-top:1px solid #e8e8e8;
  padding-left: 10px;
  line-height: 30px;
  font-weight:400;
}
#runningStateCard:hover .showInfo{
  display:block;
}
.showInfo{
  position:absolute;
  left:200;
  top:0;
  width:200px;
  height:150px;
  background:#f3f5f7;
  display:none;
}
</style>